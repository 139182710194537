import React from 'react';
import Wrapper from './index.style';
import {Link} from "gatsby";
import moment from "moment";

const Feed = ({ items, exclude = null, title = 'More News', subDescription}) => {
  return (
    <Wrapper>
      <div>
        <div className="title__block">
          <h2>{ title }</h2>
          {
            subDescription && (
              <p className="sub-description">{ subDescription }</p>
            )}
        </div>

        <div className="row">
          { items.map((post) => {

            if (exclude && post.slug === exclude) {
              return <React.Fragment />
            }

            return (
              <div className="col-lg-4 col-sm-6">
                <div className="single-post-wrap style-white">
                  <div className="thumb">
                    <Link to={`/news/${post.slug}`}>
                      <div style={{ width: '100%', backgroundImage: `url("${post.mainImage.url}")`, height: '232px', backgroundSize: 'cover' }} />
                    </Link>
                    <Link className="tag-base tag-orange" to={`/news/${post.slug}`} style={{ background: post.category.color.hex }}>{ post.category.name }</Link>
                  </div>
                  <div className="details">
                    <h6 className="title"><Link to={`/news/${post.slug}`}>{ post.title }</Link></h6>
                    <div className="post-meta-single mt-3">
                      <ul>
                        <li><i className="fa fa-clock-o"/>{ moment(post.meta.createdAt).format('MMMM Do YYYY') }</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Wrapper>
  );
}

export default Feed;
