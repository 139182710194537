import React, { Fragment } from 'react';
import {graphql, StaticQuery} from "gatsby";

import {ThemeProvider} from "styled-components";
import CookieNotice from "../components/CookieNotice";
import { charityTheme } from 'common/theme/charity';
import Footer from 'components/Footer';
import Navbar from 'components/NavbarNews';
import {
  GlobalStyle,
  CharityWrapper,
} from 'containers/Charity/charity.style';
import { ResetCSS } from 'common/assets/css/style';
import Seo from 'components/seo';
import NewsFeed from "../components/NewsFeed";
import Container from "../common/components/UI/Container";
import ESU from '../components/ESU';

const BlogDetailsPage = ({ data }) => {
  return(
    <ThemeProvider theme={charityTheme}>
      <Fragment>
        <Seo title="Home" />
        <ResetCSS />
        <GlobalStyle />
        {/* Start charity wrapper section */}
        <CharityWrapper>
          <Navbar />
          <Container width="1260px" >
            <StaticQuery
              query={graphql`
              query {
                allDatoCmsNews(
                  sort: {fields: meta___createdAt, order: DESC},
                  limit: 30,
                  filter: {visible: {eq: true}}
                ) {
                  nodes {
                    title
                    slug
                    category {
                      color {
                        hex
                      }
                      name
                    }
                    meta {
                      createdAt
                    }
                    mainImage {
                      url
                    }
                  }
                }
              }
            `}
              render={data => (
                <div style={{ paddingBottom: '5em' }}>
                  <NewsFeed items={data.allDatoCmsNews.nodes} title="Latest News" subDescription="Comprehensive Coverage of Current News & Events for Godalming and its Surrounding Villages" />
                </div>
              )}
            />
          </Container>
          <ESU data={{ title: 'Subscribe to updates', description: 'Get the latest news and updates from Godalming & Villages Residents'}} />
          <Footer />
          <CookieNotice />
        </CharityWrapper>
        {/* End of charity wrapper section */}
      </Fragment>
    </ThemeProvider>
  )
}

export default BlogDetailsPage
