import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const Wrapper = styled.div`

  
  .title__block {
    margin-bottom: 3em;

    h2 {
      margin-top: 0.75em;
      font-size: 50px;
      font-weight: 300;
      margin-bottom: 0;
    }
    
    p {
      padding-top: 0em;
    }

  }
  
  .sub-description {
    font-size: 18px;
    line-height: 40px;
    margin-bottom: 0;
  }

  .row {
      display: flex;
      flex-wrap: wrap;
  }
  
  .single-post-wrap {
    position: relative;
    margin-bottom: 30px;
    transition: all 0.4s ease 0s;
    margin: 0 1em;
    margin-bottom: 2em;
    
    .thumb {
      position: relative;
      margin-bottom: 15px;
      overflow: hidden;
      border-radius: 5px;
    }
    
    .tag-base {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 3;
        padding: 0.5em;
        color: #fff;
    }
    
    .details .title {
        margin-bottom: 0px;
    }
  }
  
  h6.title {
    font-size: 1.4em;
    margin-top: 0;
    
    a {
      color: #345e3b;
    }
  }
  
  @media (min-width: 576px) {
    .col-sm-6 {
        max-width: 50%;
        flex: 0 0 50%;
    }
  }
  
  @media (min-width: 992px) {
    .col-lg-4 {
        max-width: 33.3333%;
        flex: 0 0 33.3333%;
    }
  }
  

`;

export default Wrapper;
